import React from 'react';
import './style.css';
import LogTable from '../tables/logTable';

const LogPage = () => {
	return (
		<div className="log_page">
			<LogTable />
		</div>
	);
};

export default LogPage;
