import React from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Header from '../../layout/header';
import Preloader from '../../components/preloader';
import { URL_SERVER } from '../../utils/constants';
import AdminPanel from '../../components/adminPanel';
import AlertMessageSnackbar from '../../layout/alertMessageSnackbar';
import HeaderAdminPanel from '../../components/header';
import MainPage from '../../layout/mainPage';
import { setDataAlert } from '../../redux/actions/alert';
import Footer from '../../layout/footer';
import CustomModalWrapper from '../../layout/modalWindows/customModalWrapper';

function App() {
	const dispatch = useDispatch();
	const stateSession = useSelector((state) => state.session);
	const stateAlert = useSelector((state) => state.alert);
	const appRef = React.useRef(null);
	const preloaderRef = React.useRef(null);

	React.useEffect(() => {
		if (!stateSession.authorized) {
			const currentURL = window.location.href.includes('admin') ? 'admin' : 'lk';
			window.location.href = `${URL_SERVER}/auth?scope=${currentURL}`;
		}
	}, [stateSession])

	const onCloseAlertMessage = () => {
		dispatch(
			setDataAlert({
				show: false,
				message: '',
			})
		);
	};

	const showApp = () => {
		setTimeout(() => {
			preloaderRef.current.classList.add('hide');
			appRef.current.classList.add('show_animate');
		}, 1500);
	};

	return (
		<div>
			<Preloader propRef={preloaderRef} />
				<Router>
					<Routes>
						<Route path={'/admin'} element={
							stateSession.authorized ? <div className='App' ref={appRef}>
								<HeaderAdminPanel />
								<AdminPanel showApp={showApp} />
							</div> : null
						} />
						<Route path={'/'} element={
							stateSession.authorized ? <div className='App' ref={appRef}>
								<Header showApp={showApp} />
								<MainPage />
								<Footer />
							</div> : null
						} />
					</Routes>
				</Router>
			<AlertMessageSnackbar
				open={stateAlert.data.show}
				close={onCloseAlertMessage}
				message={stateAlert.data.message}
			/>
			<CustomModalWrapper />
		</div>
	);
}

export default App;
