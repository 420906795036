import React from 'react';
import './style.css';
import { smsProviders } from '../../utils/constants';

const SmsInfoApp = (props) => {
	const { app } = props;
	return (
		<div className="info_sms">
			<span className="info_sms__header">
				SMSC аккаунт
			</span>
			<div className="info_sms__row">
				<span className="info_sms__row__left_span">
					Логин
				</span>
				<span className="info_sms__row__right">
					{app.sms_login}
				</span>
			</div>
			<div className="info_sms__row">
				<span className="info_sms__row__left_span">
					Пароль
				</span>
				<span className="info_sms__row__right">
					{app.sms_password}
				</span>
			</div>
			{
				app.sms_provider === smsProviders.mts ?
					<div className="info_sms__row">
					<span className="info_sms__row__left_span">
						Имя отправителя
					</span>
							<span className="info_sms__row__right">
						{app.mts_sender_name}
					</span>
					</div> : null
			}
		</div>
	);
};

export default SmsInfoApp;
