export const URL_SERVER = window._env_
	? window._env_.URL_API_SERVER
	: 'http://localhost:8085';
export const VERSION_RELEASE = '1.0.1';
export const AUTH_TYPE = window._env_ ? window._env_.AUTH_TYPE : 'trustedID';
export const LICENSE_POLICY =
	`${URL_SERVER}/docs/company-icense-agreement.pdf`;
export const PERSONAL_DATA_POLICY =
	`${URL_SERVER}/docs/company-policy-for-personal-data-processing.pdf`;
export const URL_SERVICES = window._env_
	? window._env_.URL_SERVICES
	: 'https://lkdev.etcd.kloud.one';
export const URL_SERVICE_CRM_TOOLS = window._env_
	? window._env_.URL_SERVICE_CRM_TOOLS
	: 'https://crmtools-dev.kloud.one';
export const URL_SERVICE_TRUSTED = window._env_
	? window._env_.URL_SERVICE_TRUSTED
	: 'https://id.kloud.one';
export const MARKET_LINK_BUY = window._env_
	? window._env_.MARKET_LINK_BUY
	: 'https://kloud.one/id/#buy';
export const VERSION_TEXT = window._env_
	? window._env_.VERSION_TEXT
	: '1.0';
export const MAX_CODE_LENGTH = Number(window._env_
	? window._env_.MAX_CODE_LENGTH : 8);
export const MAX_APP_NAME_LENGTH = Number(window._env_
	? window._env_.MAX_APP_NAME_LENGTH : 40);
export const MESSAGE_TEMPLATE = window._env_
	? window._env_.MESSAGE_TEMPLATE : 'Код авторизации в {app}: {code}';
export const USE_SMS = window._env_ &&
	window._env_.USE_SMS === 'true';
export const BITRIX_FORM = window._env_ ?
window._env_.BITRIX_FORM : 'https://crm.digtlab.ru/upload/crm/form/loader_34_44qi8k.js';
export const SERVICE_NAME = window._env_ ?
	window._env_.SERVICE_NAME : 'АВТОРИЗАЦИЯ ПО ТЕЛЕФОНУ';
export const SERVICE_GROUP_NAME = window._env_ ?
	window._env_.SERVICE_GROUP_NAME : 'kloud.one';
export const OAUTH_DOC_LINK = window._env_ ?
	window._env_.OAUTH_DOC_LINK : "https://app.swaggerhub.com/apis/DigtTelecom/oauth2.id.kloud.one/1.0.0";
export const PAGE_DOC_LINK = window._env_ ?
	window._env_.PAGE_DOC_LINK : "https://app.swaggerhub.com/apis/DigtTelecom/id.kloud.one/1.0.0";
export const DOC_LINK = window._env_ ?
	window._env_.DOC_LINK : "https://docs.kloud.one";
export const codeTypes = {
	number:'number',
	sms:'sms',
	voice:'voice'
}
export const translateCodes = {
	number:'Звонок',
	sms:'SMS',
	voice:'ГОЛОС'
}
export const sipStatuses = {
	1: 'Зарегистрирован',
	2: 'Незарегистрирован',
	3: 'Регистрация',
}
export const smsProviders = {
	smsc: 'SMSC',
	mts: 'МТС',
}