import React from 'react';
import './style.css';
import { sipStatuses } from '../../utils/constants';

const SipInfoApp = (props) => {
	const { app } = props;
	const statusColors = {
		1: '#4ABF73',
		2: '#C91C00',
		3: '#f3cc3f',
	}
	return (
		<div className="info_sip">
			<span className="info_sip__header">
				SIP аккаунт
			</span>
			<div className="info_sip__row border_bottom">
				<span className="info_sip__row__left_span">
					Логин
				</span>
				<span className="info_sip__row__right">
					{app.sip_username}
				</span>
			</div>
			<div className="info_sip__row border_bottom">
				<span className="info_sip__row__left_span">
					Пароль
				</span>
				<span className="info_sip__row__right">
					{app.sip_secret}
				</span>
			</div>
			<div className="info_sip__row border_bottom">
				<span className="info_sip__row__left_span">
					Сервер
				</span>
				<span className="info_sip__row__right">
					{app.sip_server}
				</span>
			</div>
			<div className="info_sip__row">
				<span className="info_sip__row__left_span">
					Статус
				</span>
				<span className="info_sip__row__right">
					<div className="sip_indicator" style={{ 'backgroundColor': statusColors[app.sip_status]}}/>
					{sipStatuses[app.sip_status]}
				</span>
			</div>
		</div>
	);
};

export default SipInfoApp;
