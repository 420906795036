import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
	setAddAppModal,
	setDeleteAppModal,
	setEditAppModal,
	setBalanceModal,
	setBitrixModal
} from '../../../redux/actions/modals';
import EditAppModal from '../editApp';
import DeleteAppModal from '../deleteApp';
import BalanceModal from '../balanceModal';
import BitrixModal from '../BitrixModal';

const CustomModalWrapper = () => {
	const dispatch = useDispatch();
	const modals = useSelector((state) => state.modals);
	const apps = useSelector((state) => state.apps);
	const user = useSelector((state) => state.profile);

	const closeAddAppModal = () => {
		dispatch(
			setAddAppModal({
				show: false,
			})
		);
	};

	const closeBalanceModal = () => {
		dispatch(
			setBalanceModal({
				show: false,
				appId: '',
			})
		);
	};

	const closeEditAppModal = () => {
		dispatch(
			setEditAppModal({
				show: false,
				appId: '',
			})
		);
	};

	const closeDeleteAppModal = () => {
		dispatch(
			setDeleteAppModal({
				show: false,
				appId: '',
			})
		);
	};

	const closeBitrixModal = () => {
		dispatch(
			setBitrixModal({
				show: false,
				appId: '',
			})
		);
	};

	return (
		<div>
			{modals.addAppModal.show ? (
				<EditAppModal
					open={modals.addAppModal.show}
					setClose={closeAddAppModal}
					user={user.data}
				/>
			) : null}
			{modals.editAppModal.show ? (
				<EditAppModal
					open={modals.editAppModal.show}
					setClose={closeEditAppModal}
					app={apps.data.find((app) => app.id === modals.editAppModal.appId)}
					user={user.data}
				/>
			) : null}
			{modals.deleteAppModal.show ? (
				<DeleteAppModal
					open={modals.deleteAppModal.show}
					setClose={closeDeleteAppModal}
					app={apps.data.find((app) => app.id === modals.deleteAppModal.appId)}
				/>
			) : null}
			{modals.balanceModal.show ? (
				<BalanceModal
					open={modals.balanceModal.show}
					setClose={closeBalanceModal}
				/>
			) : null}
			{modals.bitrixModal.show ? (
				<BitrixModal
					open={modals.bitrixModal.show}
					setClose={closeBitrixModal}
				/>
			) : null}
		</div>
	);
};

export default CustomModalWrapper;
