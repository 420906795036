import React from 'react';
import './style.css';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@mui/material/CircularProgress';
import { columnsBalances } from '../../../components/tables/columns';
import { setPageBalances } from '../../../redux/actions/balances';
import RefreshIcon from '../../icons/refresh';
import { getBalances, getMoreBalances } from '../../../api/license';
import { translateCodes } from '../../../utils/constants';

const useStyles = makeStyles(() => ({
	root: {
		'& .MuiTable-root': {
			display: 'flex',
			flexDirection: 'column',
			width: '1000px',
			border: '1px solid #E0E2E7',
			borderBottom: 'none',
			boxSizing: 'border-box',
			borderRadius: 3,
		},
		'& .MuiTableRow-root': {
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'space-between',
			borderBottom: '1px solid #E0E2E7',
			padding: '0 8px',
		},
		'& .MuiTableCell-root': {
			borderBottom: 'none',
			fontFamily: 'Inter',
			fontStyle: 'normal',
			fontWeight: 'normal',
			fontSize: '15px',
			lineHeight: '22px',
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
		},
		'& .MuiTableHead-root .MuiTableCell-root': {
			color: '#8387A0',
			// padding: '16px 100px 15px 20px',
		},
		'& .MuiTableBody-root .MuiTableCell-root': {
			color: '#273270',
			// padding: '16px 100px 15px 23px',
		},
	},
}));

const LicenseTable = () => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const stateLicense = useSelector((state) => state.balances);
	const [loading, setLoading] = React.useState(false);
	const [updating, setUpdating] = React.useState(false);
	const [scrollStatus, setScrollStatus] = React.useState(false);
	const [noResultMessage, setNoResultMessage] = React.useState(false);

	const updateData = async () => {
		dispatch(setPageBalances(1));
		setUpdating(true);
		await getBalances();
		setNoResultMessage(!stateLicense.data.length);
		setTimeout(() => {
			setUpdating(false);
		}, 1000);
	};

	const scrollBottom = () => {
		if (
			document.documentElement.scrollHeight -
				document.documentElement.scrollTop -
				window.innerHeight <=
				50 &&
			!loading
		) {
			if (
				Number(
					stateLicense.totalSize - stateLicense.page * stateLicense.sizePerPage
				) > 0
			) {
				dispatch(setPageBalances(stateLicense.page + 1));
				setScrollStatus(!scrollStatus);
			}
		}
	};

	React.useEffect(() => {
		window.addEventListener('scroll', scrollBottom);
		return () => {
			window.removeEventListener('scroll', scrollBottom);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	});

	React.useEffect(() => {
		setLoading(true);
		(async () => {
			await getMoreBalances();
			setNoResultMessage(!stateLicense.data.length);
			setLoading(false);
		})();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [scrollStatus]);

	return (
		<div className="license_table">
			<TableContainer className={classes.root}>
				<Table>
					<TableHead>
						<TableRow>
							{columnsBalances.map((column) => (
								<TableCell
									align="center"
									key={column.field}
									style={{
										width: column.width,
									}}
									padding="normal"
									sortDirection={
										stateLicense.sort.sortField === column.field
											? stateLicense.sort.sortOrder
											: false
									}
								>
									{column.headerName}
								</TableCell>
							))}
							<TableCell>
								<RefreshIcon onClick={updateData} loading={updating} />
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{stateLicense.data.map((license) => (
							<TableRow key={license.id}>
								<TableCell
									align="left"
									style={{
										width: 190,
									}}
								>
									{license.createAt.slice(8, 10) +
										'.' +
										license.createAt.slice(5, 7) +
										'.' +
										license.createAt.slice(0, 4) +
										' ' +
										(new Date(new Date(license.createAt).getTime()).getHours() <
										10
											? `0${new Date(
													new Date(license.createAt).getTime()
											  ).getHours()}`
											: new Date(
													new Date(license.createAt).getTime()
											  ).getHours()) +
										':' +
										(new Date(
											new Date(license.createAt).getTime()
										).getMinutes() < 10
											? `0${new Date(
													new Date(license.createAt).getTime()
											  ).getMinutes()}`
											: new Date(
													new Date(license.createAt).getTime()
											  ).getMinutes()) +
										':' +
										(new Date(
											new Date(license.createAt).getTime()
										).getSeconds() < 10
											? `0${new Date(
													new Date(license.createAt).getTime()
											  ).getSeconds()}`
											: new Date(
													new Date(license.createAt).getTime()
											  ).getSeconds())}
								</TableCell>
								<TableCell
									align="left"
									style={{
										width: 460,
									}}
								>
									{license.license}
								</TableCell>
								<TableCell
									align="left"
									style={{
										width: 60,
									}}
								>
									{license.count}
								</TableCell>
								<TableCell
									align="left"
									style={{
										width: 60,
									}}
								>
									{translateCodes[license.type] || translateCodes.number}
								</TableCell>
								<TableCell
									align="left"
									style={{
										width: 120,
									}}
								>
									{license.status ? 'Успешно' : 'активирован'}
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
			<div className={loading ? 'license_table__preloader' : 'hidden_block'}>
				<CircularProgress />
			</div>
			<div
				className={
					noResultMessage && !stateLicense.data.length
						? 'license_table__no_result'
						: 'hidden_block'
				}
			>
				<h3>Результаты поиска</h3>
				<span className="license_table__no_result__span">
					По вашему запросу ничего не найдено
				</span>
			</div>
		</div>
	);
};

export default LicenseTable;
